import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"

// import BlogPostPreview from "./BlogPostPreview" // plasmic-import: As-VifLuol/component
import { PlasmicBlogPostPreview } from "./plasmic/isaacpalka_com/PlasmicBlogPostPreview"
import { PlasmicLatestPosts } from "./plasmic/isaacpalka_com/PlasmicLatestPosts"
import { createSubheader } from "../utils/createSubheader"

// const BlogPreview = ({ post }) => {
//   return (
//     <div className="is-parent column is-6" key={post.id}>
//       <article
//         className={`blog-list-item tile is-child box notification ${
//           post.frontmatter.featuredpost ? "is-featured" : ""
//         }`}
//       >
//         <header>
//           <p className="post-meta">
//             <Link
//               className="title has-text-primary is-size-4"
//               to={post.frontmatter.path}
//             >
//               {post.frontmatter.title}
//             </Link>
//             <span> &bull; </span>
//             <span className="subtitle is-size-5 is-block">
//               {post.frontmatter.date}
//             </span>
//           </p>
//         </header>
//         <p>
//           {post.excerpt}
//           <br />
//           <br />
//           <Link className="button" to={post.frontmatter.path}>
//             Keep Reading →
//           </Link>
//         </p>
//       </article>
//     </div>
//   )
// }

const BlogRoll = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <PlasmicLatestPosts>
      {posts &&
        posts.map(({ node: post }) => {
          const {
            id,
            frontmatter: { title, path, date },
            timeToRead,
            excerpt,
          } = post
          const subheader = createSubheader(date, timeToRead)

          return (
            <PlasmicBlogPostPreview
              key={id}
              destination={path}
              title={title}
              subheader={subheader}
              excerpt={excerpt}
            />
          )
        })}
    </PlasmicLatestPosts>
  )
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

const query = () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              id
              frontmatter {
                path
                title
                date(formatString: "MMM DD, YYYY")
              }
              excerpt(pruneLength: 400)
              timeToRead
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <BlogRoll
        data={data}
        // count={count}
      />
    )}
  />
)

export default query
