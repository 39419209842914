// extracted by mini-css-extract-plugin
export var back = "PlasmicBlog-module--back--4dad4";
export var copyright = "PlasmicBlog-module--copyright--1acf3";
export var freeBox__c5BPc = "PlasmicBlog-module--freeBox__c5BPc--0b54e";
export var freeBox__jyfP9 = "PlasmicBlog-module--freeBox__jyfP9--119dd";
export var freeBox__zhk3L = "PlasmicBlog-module--freeBox__zhk3L--35d81";
export var h1 = "PlasmicBlog-module--h1--c0dca";
export var latestPostsWrapper = "PlasmicBlog-module--latestPostsWrapper--28573";
export var p__aTef = "PlasmicBlog-module--p__aTef--ebc38";
export var p__wLwu5 = "PlasmicBlog-module--p__wLwu5--68b3d";
export var root = "PlasmicBlog-module--root--5e341";